<!--
 * @Description: 文件描述
-->
<template>
  <div class="header_area_wrapper">
    <h4 class="title">金额（元）</h4>
    <a-statistic class="amount" :precision="2" :value="amount / 100" />
    <!-- <h2 class="amount">{{ amount }}</h2> -->
    <a-button
      v-auth:ORGANIZATION_FINANCE_RECHARGE
      type="primary"
      size="small"
      @click="handleDeposit"
    >充值</a-button>

    <!-- 充值弹窗 -->
    <!-- <DepositMoneyDialog ref="DepositMoneyDialog"></DepositMoneyDialog> -->
  </div>
</template>

<script>
// import DepositMoneyDialog from '../dialogs/DepositMoneyDialog'
import ApiController from '../api'

export default {
  components: {
    // DepositMoneyDialog
  },
  data() {
    return {
      amount: ''
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      const data = await ApiController.getBalance()
      this.amount = data
    },
    handleDeposit() {
      // this.$refs.DepositMoneyDialog.show = true
      this.$router.push('/finance/deposit-money')
    }
  }
}
</script>

<style lang="scss" scoped>
.header_area_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 1.5em;
    margin-bottom: 0;
  }

  .amount {
    font-size: 2.5em;
    margin: 10px 0;
    font-weight: bold;
    color: #333;
  }
}
</style>
