<!--
 * @Description: 文件描述
-->
<template>
  <vxe-modal
    title="预览图片"
    v-model="show"
    class="ImgSwiper"
    @show="handleInit"
    width="80%"
    height="90%"
    show-footer
  >
    <div class="thumb-example">
      <!-- swiper1 -->
      <swiper class="swiper gallery-top" :options="swiperOptionTop" ref="swiperTop">
        <swiper-slide v-for="(img, index) in imgList" :key="index" :class="`slide${index}`">
          <div class="swiper-zoom-container">
            <img :src="img" />
          </div>
        </swiper-slide>

        <template v-if="imgList.length > 1">
          <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
          <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
        </template>
      </swiper>

      <!-- swiper2 Thumbs -->
      <swiper
        class="swiper gallery-thumbs"
        :options="swiperOptionThumbs"
        ref="swiperThumbs"
        v-show="imgList.length > 1"
      >
        <swiper-slide v-for="(img, index) in imgList" :key="index" :class="`slide${index}`">
          <div class="swiper-zoom-container">
            <div :style="{ backgroundImage: `url(${img})` }" class="thumb_img_item"></div>
            <!-- <img :src="img" class="thumb_img" /> -->
          </div>
        </swiper-slide>
      </swiper>
    </div>

    <template #footer>
      <p>
        <a-icon type="info-circle" theme="twoTone" />
        <span class="content">双击放大图片，放大后可拖动</span>
      </p>
    </template>
  </vxe-modal>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'SwiperExampleThumbsGallery',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    imgList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      show: false,
      swiperOptionTop: {
        // loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        zoom: {
          maxRatio: 5
        }
      },
      swiperOptionThumbs: {
        // loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: 'auto',
        touchRatio: 0.2,
        slideToClickedSlide: true
      }
    }
  },
  methods: {
    handleInit() {
      this.$nextTick(() => {
        this.swiperTop = this.$refs.swiperTop.$swiper
        this.swiperThumbs = this.$refs.swiperThumbs.$swiper
        this.swiperTop.controller.control = this.swiperThumbs
        this.swiperThumbs.controller.control = this.swiperTop

        this.swiperTop.on('zoomChange', (_, scale) => {
          if (scale > 1) {
            this.swiperTop.allowSlideNext = false
            this.swiperTop.allowSlidePrev = false
          } else {
            this.swiperTop.allowSlideNext = true
            this.swiperTop.allowSlidePrev = true
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.thumb-example {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  background-color: #000;
}
.swiper {
  &.gallery-top {
    /* height: 80%; */
    flex: 1;
    width: 100%;
  }

  &.gallery-thumbs {
    /* margin-top: 10px; */
    height: 20%;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 0;
  }

  &.gallery-thumbs .swiper-slide {
    width: 15%;
    height: 100%;
    opacity: 0.4;
  }
  &.gallery-thumbs .swiper-slide-active {
    opacity: 1;
  }

  .thumb_img {
    width: 100%;
    height: 100%;
  }

  .thumb_img_item {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
  }
}

.content {
  display: inline-block;
  margin-left: 0.5em;
  font-weight: bold;
  font-size: 10px;
}
</style>
