<!--
 * @Description: 文件描述
-->
<template>
  <page-header-wrapper>
    <a-card class="finance_order_list_wrapper page_content">
      <!-- 标题区域 -->
      <HeaderArea class="header_area"></HeaderArea>
      <!-- tab区域 -->
      <TabArea v-model="currentTab"></TabArea>
      <!-- 表单区域 -->
      <MainTable class="main_table" :data="tableData" :loading="loading"></MainTable>

      <!-- 分页 -->
      <Pagination :pagination.sync="pagination" @change="fetchData" />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import HeaderArea from './components/HeaderArea.vue'
import TabArea from './components/TabArea.vue'
import MainTable from './components/MainTable.vue'
import ApiController from './api'

export default {
  name: 'OrderList',
  components: {
    HeaderArea,
    TabArea,
    MainTable
  },
  data() {
    return {
      currentTab: '',
      loading: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      tableData: []
    }
  },
  mounted() {
    this.fetchData()
  },
  watch: {
    currentTab() {
      this.$nextTick(() => {
        this.fetchData()
      })
    }
  },
  methods: {
    // 拉取数据
    async fetchData() {
      const params = {
        type: this.currentTab,
        pageNumber: this.pagination.current,
        pageSize: this.pagination.pageSize
      }
      try {
        this.loading = true
        const { data, total } = await ApiController.getList(params)
        this.tableData = data
        this.pagination.total = total
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.finance_order_list_wrapper {
  height: calc(100vh - 190px);

  .header_area {
    height: 130px;
  }

  .tab_area {
    height: 40px;
  }

  ::v-deep .ant-card-body {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
</style>
