var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header_area_wrapper" },
    [
      _c("h4", { staticClass: "title" }, [_vm._v("金额（元）")]),
      _c("a-statistic", {
        staticClass: "amount",
        attrs: { precision: 2, value: _vm.amount / 100 },
      }),
      _c(
        "a-button",
        {
          directives: [
            {
              name: "auth",
              rawName: "v-auth:ORGANIZATION_FINANCE_RECHARGE",
              arg: "ORGANIZATION_FINANCE_RECHARGE",
            },
          ],
          attrs: { type: "primary", size: "small" },
          on: { click: _vm.handleDeposit },
        },
        [_vm._v("充值")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }