<!--
 * @Description: 驳回原因窗口
-->

<template>
  <a-modal v-model="show" title="驳回原因">
    <a-spin :spinning="loading" class="main_content">
      <a-form-model class="reject_reason_form" :model="form" ref="form" v-bind="formLayout">
        <a-form-model-item prop="remark" label="驳回原因">
          <span>{{ form.remark }}</span>
        </a-form-model-item>

        <a-form-model-item prop="operationUserName" label="处理人">
          <span>{{ form.operationUserName }}</span>
        </a-form-model-item>

        <a-form-model-item prop="operationTime" label="处理时间">
          <span>{{ form.operationTime }}</span>
        </a-form-model-item>
      </a-form-model>
    </a-spin>

    <template #footer>
      <a-button type="primary" @click="show = false">确定</a-button>
    </template>
  </a-modal>
</template>

<script>
import DEFAULT_FORM_LAYOUT from '@/config/formLayout'
import ApiController from '@/views/finance/api'

const FORM = () => ({
  reason: '',
  operationUserName: '',
  createTime: ''
})

export default {
  data() {
    return {
      formLayout: DEFAULT_FORM_LAYOUT,
      show: false,
      loading: false,
      form: FORM()
    }
  },
  methods: {
    async handleShow(row) {
      this.show = true
      this.form = FORM()

      try {
        this.loading = true
        const res = await ApiController.rejectReason({ id: row.financeId })
        this.form = { ...res }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.reject_reason_form {
  ::v-deep .ant-form-item {
    margin-bottom: 5px;
  }
}
</style>
