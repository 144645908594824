var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vxe-modal",
    {
      staticClass: "ImgSwiper",
      attrs: {
        title: "预览图片",
        width: "80%",
        height: "90%",
        "show-footer": "",
      },
      on: { show: _vm.handleInit },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "p",
                [
                  _c("a-icon", {
                    attrs: { type: "info-circle", theme: "twoTone" },
                  }),
                  _c("span", { staticClass: "content" }, [
                    _vm._v("双击放大图片，放大后可拖动"),
                  ]),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "div",
        { staticClass: "thumb-example" },
        [
          _c(
            "swiper",
            {
              ref: "swiperTop",
              staticClass: "swiper gallery-top",
              attrs: { options: _vm.swiperOptionTop },
            },
            [
              _vm._l(_vm.imgList, function (img, index) {
                return _c(
                  "swiper-slide",
                  { key: index, class: "slide" + index },
                  [
                    _c("div", { staticClass: "swiper-zoom-container" }, [
                      _c("img", { attrs: { src: img } }),
                    ]),
                  ]
                )
              }),
              _vm.imgList.length > 1
                ? [
                    _c("div", {
                      staticClass: "swiper-button-next swiper-button-white",
                      attrs: { slot: "button-next" },
                      slot: "button-next",
                    }),
                    _c("div", {
                      staticClass: "swiper-button-prev swiper-button-white",
                      attrs: { slot: "button-prev" },
                      slot: "button-prev",
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "swiper",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.imgList.length > 1,
                  expression: "imgList.length > 1",
                },
              ],
              ref: "swiperThumbs",
              staticClass: "swiper gallery-thumbs",
              attrs: { options: _vm.swiperOptionThumbs },
            },
            _vm._l(_vm.imgList, function (img, index) {
              return _c(
                "swiper-slide",
                { key: index, class: "slide" + index },
                [
                  _c("div", { staticClass: "swiper-zoom-container" }, [
                    _c("div", {
                      staticClass: "thumb_img_item",
                      style: { backgroundImage: "url(" + img + ")" },
                    }),
                  ]),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }