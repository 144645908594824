<!--
 * @Description: 表格区域
-->
<template>
  <div class="table_content">
    <!-- 表格加一层wrapper, 方便 height=auto 处理 -->
    <div class="custom_vxe_table_wrapper">
      <vxe-table height="auto" v-bind="$attrs">
        <vxe-column title="类型" field="type" :formatter="['enum', FINANCE_RECORD_TYPE_ENUM]"></vxe-column>
        <vxe-column title="项目名称" field="projectName"></vxe-column>
        <vxe-column title="时间" field="createtime" :formatter="['date', 'YYYY-MM-DD HH:mm:ss']"></vxe-column>
        <vxe-column title="交易单号" field="orderCode"></vxe-column>
        <vxe-column title="金额(元)" field="balanceChange" align="right" formatter="price"></vxe-column>
        <vxe-column title="回执" field="havReceipt" align="center">
          <template #default="{ row }">
            <a-button
              type="link"
              v-if="row.type === 'BALANCE_RECHARGE'"
              v-auth:ORGANIZATION_FINANCE_VIEW_RECEIPT
              @click="() => handleViewReceipt(row)"
            >查看</a-button>
          </template>
        </vxe-column>
        <vxe-column title="充值状态" field="status" :formatter="['enum', ORDER_STATUS_ENUM]"></vxe-column>
        <vxe-column title="账户余额" field="balance" formatter="price" align="right"></vxe-column>
        <vxe-column title="操作" field="action" align="right">
          <template #default="{ row }">
            <template v-if="row.status === 'REJECTED'">
              <a-button
                type="link"
                v-auth:ORGANIZATION_FINANCE_REASONS_REJECTION
                @click="() => handleShowRejectReason(row)"
              >驳回原因</a-button>
              <a-button type="link" @click="() => handleResubmit(row)">重新提交</a-button>
            </template>
          </template>
        </vxe-column>
      </vxe-table>
    </div>

    <!-- 查看驳回原因窗口 -->
    <RejectReasonDialog ref="RejectReasonDialog"></RejectReasonDialog>

    <!-- 查看回执窗口 -->
    <ImgSwiper ref="ImgSwiper" :imgList="imgList"></ImgSwiper>
  </div>
</template>

<script>
import RejectReasonDialog from '../dialogs/RejectReasonDialog.vue'
import { viewReceiptList } from '@/views/finance/api'
import ImgSwiper from '../dialogs/ImgSwiper.vue'
import { ORDER_STATUS_ENUM, FINANCE_RECORD_TYPE_ENUM } from '../_const'

export default {
  components: {
    RejectReasonDialog,
    ImgSwiper
  },
  data() {
    return {
      ORDER_STATUS_ENUM,
      FINANCE_RECORD_TYPE_ENUM,
      imgList: []
    }
  },
  methods: {
    // 查看驳回原因
    handleShowRejectReason(row) {
      this.$refs.RejectReasonDialog.handleShow(row)
    },
    handleResubmit(row) {
      this.$router.push(`/finance/resubmit-order/${row.financeId}`)
    },
    handleViewReceipt(row) {
      const params = { id: row.financeId }
      viewReceiptList(params).then((res) => {
        this.imgList = res || []
        this.$refs.ImgSwiper.show = true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.table_content {
  flex: 1;
  .custom_vxe_table_wrapper {
    height: 100%;
  }
}
</style>
