/*
 * @Description: 文件描述
 */

// 订单状态枚举
const ORDER_STATUS_ENUM = {
  CONFIRMED: '已确认',
  TO_BE_CONFIRMED: '待确认',
  REJECTED: '已驳回'
}

const FINANCE_RECORD_TYPE_ENUM = {
  BALANCE_RECHARGE: '余额充值',
  ITEM_OF_EXPENDITURE: '项目支出',
  PROJECT_REFUND: '项目退款',
  PROJECT_SUPPLEMENT: '项目补款',
}

export {
  ORDER_STATUS_ENUM,
  FINANCE_RECORD_TYPE_ENUM
}
