var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table_content" },
    [
      _c(
        "div",
        { staticClass: "custom_vxe_table_wrapper" },
        [
          _c(
            "vxe-table",
            _vm._b(
              { attrs: { height: "auto" } },
              "vxe-table",
              _vm.$attrs,
              false
            ),
            [
              _c("vxe-column", {
                attrs: {
                  title: "类型",
                  field: "type",
                  formatter: ["enum", _vm.FINANCE_RECORD_TYPE_ENUM],
                },
              }),
              _c("vxe-column", {
                attrs: { title: "项目名称", field: "projectName" },
              }),
              _c("vxe-column", {
                attrs: {
                  title: "时间",
                  field: "createtime",
                  formatter: ["date", "YYYY-MM-DD HH:mm:ss"],
                },
              }),
              _c("vxe-column", {
                attrs: { title: "交易单号", field: "orderCode" },
              }),
              _c("vxe-column", {
                attrs: {
                  title: "金额(元)",
                  field: "balanceChange",
                  align: "right",
                  formatter: "price",
                },
              }),
              _c("vxe-column", {
                attrs: { title: "回执", field: "havReceipt", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.type === "BALANCE_RECHARGE"
                          ? _c(
                              "a-button",
                              {
                                directives: [
                                  {
                                    name: "auth",
                                    rawName:
                                      "v-auth:ORGANIZATION_FINANCE_VIEW_RECEIPT",
                                    arg: "ORGANIZATION_FINANCE_VIEW_RECEIPT",
                                  },
                                ],
                                attrs: { type: "link" },
                                on: {
                                  click: function () {
                                    return _vm.handleViewReceipt(row)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("vxe-column", {
                attrs: {
                  title: "充值状态",
                  field: "status",
                  formatter: ["enum", _vm.ORDER_STATUS_ENUM],
                },
              }),
              _c("vxe-column", {
                attrs: {
                  title: "账户余额",
                  field: "balance",
                  formatter: "price",
                  align: "right",
                },
              }),
              _c("vxe-column", {
                attrs: { title: "操作", field: "action", align: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.status === "REJECTED"
                          ? [
                              _c(
                                "a-button",
                                {
                                  directives: [
                                    {
                                      name: "auth",
                                      rawName:
                                        "v-auth:ORGANIZATION_FINANCE_REASONS_REJECTION",
                                      arg: "ORGANIZATION_FINANCE_REASONS_REJECTION",
                                    },
                                  ],
                                  attrs: { type: "link" },
                                  on: {
                                    click: function () {
                                      return _vm.handleShowRejectReason(row)
                                    },
                                  },
                                },
                                [_vm._v("驳回原因")]
                              ),
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "link" },
                                  on: {
                                    click: function () {
                                      return _vm.handleResubmit(row)
                                    },
                                  },
                                },
                                [_vm._v("重新提交")]
                              ),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("RejectReasonDialog", { ref: "RejectReasonDialog" }),
      _c("ImgSwiper", { ref: "ImgSwiper", attrs: { imgList: _vm.imgList } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }