var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    [
      _c(
        "a-card",
        { staticClass: "finance_order_list_wrapper page_content" },
        [
          _c("HeaderArea", { staticClass: "header_area" }),
          _c("TabArea", {
            model: {
              value: _vm.currentTab,
              callback: function ($$v) {
                _vm.currentTab = $$v
              },
              expression: "currentTab",
            },
          }),
          _c("MainTable", {
            staticClass: "main_table",
            attrs: { data: _vm.tableData, loading: _vm.loading },
          }),
          _c("Pagination", {
            attrs: { pagination: _vm.pagination },
            on: {
              "update:pagination": function ($event) {
                _vm.pagination = $event
              },
              change: _vm.fetchData,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }