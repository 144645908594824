<!--
 * @Description: 文件描述
-->
<template>
  <a-tabs :activeKey="value" @change="handleInput">
    <a-tab-pane key="" tab="全部"></a-tab-pane>
    <a-tab-pane key="BALANCE_RECHARGE" tab="余额充值" force-render></a-tab-pane>
    <a-tab-pane key="ITEM_OF_EXPENDITURE" tab="项目支出"></a-tab-pane>
    <a-tab-pane key="PROJECT_REFUND" tab="项目补款"></a-tab-pane>
    <a-tab-pane key="PROJECT_SUPPLEMENT" tab="项目退款"></a-tab-pane>
  </a-tabs>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  methods: {
    handleInput(newVal) {
      this.$emit('input', newVal)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
